.container{
    padding-left: 0;
    padding-right: 0;
    .row{

        .col-sm-12{
            div{
                ul{
                    margin-left: 0;
                    padding-left: 0;
                    li{
                        list-style: none;
                    }
                }
            }
        }
    }
}