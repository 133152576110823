#stepper-two {
  .body {
    .form {
      form {
        .row {
          .col {
            .form-group {
              label {
                font-family: "Nunito-SemiBold";
                font-size: 14px;
                line-height: 19px;
                color: #1c1e47;
              }

              input {
                border: 0.8px solid #435574;
              }

              .error {
                font-family: "Nunito-SemiBold";
                font-size: 14px;
              }
            }
            .image-upload-section {
              width: 168px;
              height: 181px;
              margin-top: 10px;
              .header {
                font-family: "Nunito-SemiBold";
                font-size: 14px;
                line-height: 19px;
                color: #1c1e47;
              }
              .body {
                padding-top: 0px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: #c2c8cb;
                .src {
                  width: 100%;
                  height: 130px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;

                  cursor: pointer;

                  #primage_image {
                    height: 130px;
                    width: 100%;
                    object-fit: cover;
                    position: absolute;
                  }
                }
                .upload-img {
                  height: 30px;
                  background: #778b9f;
                  width: 100%;
                  border: none;
                  color: #ffffff;
                  font-family: "Nunito-SemiBold";
                  font-size: 12px;
                  line-height: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }

        .form-group {
          padding-top: 5px;
          padding-bottom: 5px;
          label {
            font-family: "Nunito-SemiBold";
            font-size: 14px;
            line-height: 19px;
            color: #1c1e47;
          }
          input {
            border: 0.8px solid #435574;
          }

          .error {
            font-family: "Nunito-SemiBold";
            font-size: 14px;
          }
        }
      }
    }
  }
}
