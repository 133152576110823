#stepper-five {
    width: 676px;
    height: 438px;
    margin: auto;
    .congress {
      width: 448px;
      height: 298px;
      margin: auto;
      background-image: url(../../assets/images/congres-bg.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      object-fit: cover;
      text-align: center;
      .title {
        font-size: 22px;
        line-height: 30px;
        color: #1c1e47;
        font-family: "Nunito-Bold";
        padding-bottom: 20px;
      }
      .subtitle {
        font-size: 14px;
        line-height: 19px;
        color: #1c1e47;
        font-family: "Nunito-Regular";
        padding-bottom: 20px;
        width: 294px;
      }
      .login-button {
        a {
          display: block;
          width: 102px;
          background: linear-gradient(#25274ee6, #26284fad);
          font-family: "Nunito-Bold";
          color: #ffffff;
          font-size: 15px;
          line-height: 20px;
          border-radius: 22px;
          padding-top: 7px;
          padding-bottom: 7px;
        }
      }
    }
  }