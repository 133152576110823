@import "../../assets/sass/variable.scss";

@each $width in $resposive-width {
  @media screen and (max-width: #{$width}px) {
    #stepper-four {
      .body {
        .form {
          form {
            .row {
              .col-sm-12 {
                .form-group {
                  label {
                  }

                  .form-check {
                    input {
                      &::after {
                      }
                    }
                    label {
                    }
                  }

                  #custominputopens {
                    &.open-cusom {
                    }
                  }
                  input {
                  }
                  input::placeholder {
                  }
                }
              }

              .col-sm-12 {
                label {
                }
                .dress-choose {
                  @if ($width >= 768) {
                  } @else if($width >= 415) {
                    overflow: hidden;
                    overflow-x: auto;
                  }
                  &::-webkit-scrollbar {
                    height: 8px;
                    overflow: visible;
                  }
                  .dress {
                    margin-right: 12px;
                    width: 110px;
                    input[type="radio"] {
                      display: none;
                      &:checked {
                        ~ .dress-pic {
                          &.jacket {
                            background: #fbc52f;
                          }

                          &.aprone {
                            background: #fbc52f;
                          }

                          &.hat {
                            background: #fbc52f;
                          }

                          &.palate {
                          }
                        }
                      }
                    }
                    .dress-pic {
                      &.jacket {
                        &.active {
                        }
                      }

                      &.aprone {
                        &.active {
                        }
                      }

                      &.hat {
                        &.active {
                        }
                      }

                      &.palate {
                        &.active {
                        }
                      }
                      img {
                      }
                    }
                    .text {
                    }
                  }
                }
              }
            }
            .form-group {
              .row {
                .col-sm-12 {
                  .form-check {
                    label {
                      span {
                      }

                      input[type="radio"] {
                        &:checked {
                          ~ span {
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.error {
  font-size: 12px;
}
