.login-page {
  .lg-page-body {
    width: 100%;
    height: 100vh;
    .left-sec {
      width: 35%;
      .left-image {
        height: 100%;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .right-sec {
      width: 65%;
      display: flex;
      justify-content: center;
      align-items: center;

      .form-section {
        width: 474px;
        height: 518px;
        background: transparent
          linear-gradient(
            223deg,
            rgba(73, 106, 123, 0.15),
            rgba(114, 130, 159, 0.15)
          )
          0% 0% no-repeat padding-box;
        border-radius: 28px;

        text-align: center;

        .main-form-sec {
          .header {
            padding-top: 30px;
            .header-img {
              img {
                width: 83px;
                height: 102px;
              }
            }
            .title {
              padding-top: 21px;
              font-family: "Nunito-Bold";
              font-size: 24px;
              line-height: 32px;
              color: #3b4d67;
            }
            .sub-title {
              padding-top: 7px;
              font-size: 13px;
              color: #778b9f;
              line-height: 18px;
            }
          }

          .body {
            margin-top: 47px;
            .buttons {
              display: flex;
              flex-direction: column;
              align-items: center;
              div {
                .lg-button {
                  display: block;
                  text-decoration: none;
                  &.log {
                    width: 400px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    border-radius: 19px;
                    font-family: "Nunito-Black";
                    font-size: 14px;
                    line-height: 19px;
                    color: #ffffff;
                    background: linear-gradient(#25274ee6, #26284fad);
                  }
                  &.reg {
                    margin-top: 45px;
                    width: 400px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    border-radius: 19px;
                    font-family: "Nunito-Bold";
                    font-size: 14px;
                    line-height: 19px;
                    color: #3b4d67;
                    background: #ffffff;
                    box-shadow: 0px 2px 8px #4857724d;
                  }
                  &.chef-reg {
                    margin-top: 14px;
                    width: 400px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    border-radius: 19px;
                    font-family: "Nunito-Bold";
                    font-size: 14px;
                    line-height: 19px;
                    color: #3b4d67;
                    background: #ffffff;
                    box-shadow: 0px 2px 8px #4857724d;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
