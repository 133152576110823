header {
  .nav-section {
    nav {
      width: 100%;
      height: 75px;
      background: #ffff;
      box-shadow: 0px 3px 6px #c9c9c929;
      a {
        img {
          width: 45px;
          height: 55px;
        }
      }
    }
  }
}
