@import "../../assets/sass/variable.scss";

@each $width in $resposive-width {
    @media screen and (max-width: #{$width}px) {
        #stepper-two {
            .body {
              .form {
                form {
                  .row {
                    .col {
                      .form-group {
                        @if($width >= 414){
                          margin-bottom: 5px;
                        }
                        label {
                          @if($width >= 414){
                            font-size: 12px;
                          }
                        }

                        input {
                         
                        }

                        .error{
                            @if($width >= 414){
                                font-size: 12px;
                            }
                        }
                      }
                      .image-upload-section {
                        
                        .header {
                          
                        }
                        .body {
                          
                          .src {
                           
        
                            #primage_image {
                            
                            }
                          }
                          .upload-img {
                            
                          }
                        }
                      }
                    }
                  }
        
                  .form-group {
                    @if($width >= 414){
                        margin-bottom: 5px;
                      }
                      label {
                        @if($width >= 414){
                          font-size: 12px;
                        }
                      }
                      input {
                        
                      }
                      .error{
                          @if($width >= 414){
                              font-size: 12px;
                          }
                      }
                  }
                }
              }
            }
          }
    }
}