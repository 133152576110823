@font-face {
    font-family: 'Nunito-Bold';
    src: url('../fonts/Nunito/Nunito-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Nunito-Black';
    src: url('../fonts/Nunito/Nunito-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  
  @font-face {
    font-family: 'Nunito-SemiBold';
    src: url('../fonts/Nunito/Nunito-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  
  @font-face {
    font-family: 'Nunito-Regular';
    src: url('../fonts/Nunito/Nunito-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  
  
