@import "../../assets/sass/variable.scss";

@each $width in $resposive-width {
  @media screen and (max-width: #{$width}px) {
    #stepper-five {
      @if ($width >= 768) {
        width: 100%;
      }
      .congress {
        @if ($width >= 415) {
          width: 100%;
        }
        .title {
        }
        .subtitle {
        }
        .login-button {
          a {
          }
        }
      }
    }
  }
}
