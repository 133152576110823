@import "../../assets/sass/variable.scss";

@each $width in $resposive-width {
  @media screen and (max-width: #{$width}px) {
    section {
      &.login-page {
        .lg-page-body {
          @if ($width >= 768) {
            justify-content: center;
            align-items: center;
          }
          .left-sec {
            @if ($width >= 768) {
              display: none;
            }

            .left-image {
              img {
              }
            }
          }
          .right-sec {
            @if ($width >= 768) {
            } @else if($width >= 415) {
            }

            .form-section {
              @if ($width >= 768) {
                width: 570px;
                height: 800px;
              } @else if($width == 414) {
                width: 370px;
                height: 600px;
              } @else if($width >= 380) {
                width: 330px;
                height: 500px;
              } @else if($width >= 350) {
                width: 300px;
                height: 525px;
              }

              .main-form-sec {
                @if ($width >= 767) {
                  width: 570px;
                  height: 800px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                } @else if($width >= 414) {
                  width: 370px;
                  height: 600px;
                } @else if($width >= 380) {
                  width: 330px;
                  height: 500px;
                } @else if($width >= 350) {
                  width: 300px;
                  height: 525px;
                }
                .header {
                  @if ($width >= 768) {
                    padding-top: 0;
                  } @else if($width >= 415) {
                  }

                  .header-img {
                    img {
                      @if ($width >= 768) {
                      } @else if($width >= 415) {
                        height: 80px;
                      }
                    }
                  }
                  .title {
                  }
                  .sub-title {
                  }
                }

                .body {
                  .buttons {
                    div {
                      .lg-button {
                        &.log {
                          @if ($width >= 768) {
                          } @else if($width >= 414) {
                            width: 300px;
                          } @else if($width >= 350) {
                            width: 275px;
                          }
                        }
                        &.reg {
                          @if ($width >= 768) {
                          } @else if($width >= 414) {
                            width: 300px;
                          } @else if($width >= 350) {
                            width: 275px;
                          }
                        }
                        &.chef-reg {
                          @if ($width >= 768) {
                          } @else if($width >= 414) {
                            width: 300px;
                          } @else if($width >= 350) {
                            width: 275px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
