@import "../../assets/sass/variable.scss";

@each $width in $resposive-width {
  @media screen and (max-width: #{$width}px) {
    #stepper-three {
      .body {
        .form {
          form {
            .row {
              .col-sm-12 {
                .form-group {
                  @if ($width >= 414) {
                    margin-bottom: 10px;
                  }
                  label {
                    @if ($width >= 414) {
                      font-size: 12px;
                    }
                  }

                  input {
                  }

                  select {
                  }

                  .error {
                    @if ($width >= 414) {
                      font-size: 12px;
                    }
                  }
                }
                .image-upload-section {
                  .header {
                  }
                  .body {
                    .src {
                    }
                    .upload-img {
                    }
                  }
                }
              }
            }

            .form-group {
              @if ($width >= 414) {
                margin-bottom: 5px;
              }
              label {
                @if ($width >= 414) {
                  font-size: 12px;
                }
              }
              input {
              }

              @if ($width >= 414) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
