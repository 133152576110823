@import "../../assets/sass/variable.scss";

@each $width in $resposive-width {
  @media screen and (max-width: #{$width}px) {
    .steppers {
      .container {
        .row {
          .col-12 {
            .step {
              @if ($width >= 767) {
              } @else if($width >= 415) {
                padding-left: 20px;
                padding-right: 20px;
              }
              .header {
                .title {
                  @if ($width >= 414) {
                    font-size: 18px;
                  }
                }
                .subtitle {
                  @if ($width >= 414px) {
                    font-size: 13px;
                  }
                }
              }

              .body {
                .content {
                  @if ($width >= 768) {
                  } @else if($width >= 415) {
                    text-align: justify;
                    font-size: 13px;
                    line-height: 23px;
                  }
                }

                .accepts-section {
                  .accepts {
                    div {
                      @if ($width >= 380) {
                        font-size: 11px;
                        padding-left: 7px;
                        line-height: 22px;
                        padding-right: 7px;
                      }
                    }
                    input[type="radio"] {
                      &:checked {
                        ~ div {
                        }
                      }
                    }

                    &.active-accepts {
                    }
                  }
                }

                .continue {
                  .accept-continue {
                    @if ($width >= 380) {
                      font-size: 10px;
                      width: 130px;
                      height: 30px;
                    }

                    &.unique-accept {
                      @if ($width >= 300) {
                        width: 210px;
                        height: 30px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
