#stepper-four {
  .body {
    .form {
      #recv_approve_form {
        .row {
          .col-sm-12 {
            label {
              padding-bottom: 5px;
            }
            .form-group {
              label {
                input[type="radio"] {
                  display: none;
                  &:checked {
                    ~ span {
                      background-color: #f8c530;
                      color: #ffffff;
                    }
                  }
                }
                span {
                  width: 85px;
                  margin-right: 7px;
                  margin-bottom: 7px;

                  height: 30px;
                  border: 1px solid #ecb922;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 14px;
                  list-style: 19px;
                  font-family: "Nunito-Regular";
                }
              }
            }
          }

          .dress-choose {
            .dress {
              width: 110px;
              margin-right: 7px;
              font-family: "Nunito-SemiBold";
              font-size: 14px;
              line-height: 19px;
              color: #1c1e47;
              margin-bottom: 7px;
              .text {
                margin-top: 5px;
                font-size: 12px;
                font-family: "Nunito-Regular";
                line-height: 16px;
                color: #1c1e47;
                text-align: center;
              }
              input[type="radio"] {
                display: none;
                &:checked {
                  ~ .dress-pic {
                    &.jacket {
                      width: 110px;
                      background: #fbc52f;
                    }

                    &.aprone {
                      width: 110px;

                      background: #fbc52f;
                    }

                    &.hat {
                      width: 110px;

                      background: #fbc52f;
                    }

                    &.palate {
                      width: 110px;

                      background: #fbc52f;
                    }
                  }
                }
              }

              .dress-pic {
                background: #f6f8f9;
                border: 1px solid #778b9f;
                height: 75px;
                border-radius: 11px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.jacket {
                  width: 110px;
                  &.active {
                    background: #fbc52f;
                  }
                }

                &.aprone {
                  width: 110px;

                  &.active {
                    background: #fbc52f;
                  }
                }

                &.hat {
                  width: 110px;

                  &.active {
                    background: #fbc52f;
                  }
                }

                &.palate {
                  width: 110px;

                  &.active {
                    background: #fbc52f;
                  }
                }
                img {
                  width: 60px;
                  height: 60px;
                }
              }
            }
          }
        }

        .form-group {
          .row {
            .col-sm-12 {
              label {
                input[type="radio"] {
                  display: none;
                  &:checked {
                    ~ span {
                      background-color: #f8c530;
                      color: #ffffff;
                    }
                  }
                }
                span {
                  width: 55px;
                  margin-right: 7px;
                  margin-bottom: 7px;
                  height: 30px;
                  border: 1px solid #ecb922;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 14px;
                  list-style: 19px;
                  font-family: "Nunito-Regular";
                }
              }
            }
          }
        }
      }
    }
  }
}
