.steppers {
  padding-top: 15px;

  .container {
    max-width: 986px;
    .row {
      .col-12 {
        .step {
          background: #fcfcfc;
          box-shadow: 0px 2px 3px #3b608029;
          padding-left: 113px;
          padding-right: 113px;
          padding-top: 46px;
          padding-bottom: 56px;

          .header {
            .title {
              font-family: "Nunito-Bold";
              font-size: 22px;
              line-height: 31px;
              color: #1c1e47;
            }
            .subtitle {
              font-family: "Nunito-SemiBold";
              font-size: 14px;
              line-height: 19px;
              color: #778b9f;
            }
          }

          .body {
            padding-top: 25px;
            .content {
              font-family: "Nunito-Regular";
              font-size: 18px;
              line-height: 27px;
              color: #070707;
            }

            .accepts-section {
              margin-top: 21px;
              h6 {
                font-family: "Nunito-Bold";
              }
              .accepts {
                width: 100%;
                div {
                  min-height: 65px;
                  border: 3px solid #ecb922;
                  padding-left: 18px;
                  padding-right: 66px;
                  padding-top: 10px;
                  padding-bottom: 9px;
                  margin-bottom: 8px;
                  font-family: "Nunito-Regular";
                  font-size: 16px;
                  line-height: 24px;
                }
                input[type="radio"] {
                  display: none;
                  &:checked {
                    ~ div {
                      background: #f8c530;
                      color: #ffffff;
                    }
                  }
                }

                &.active-accepts {
                }
              }
            }

            .continue {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 21px;
              .accept-continue {
                width: 160px;
                background: linear-gradient(#25274ee6, #26284fad);
                color: #ffffff;
                font-family: "Nunito-Bold";
                font-size: 15px;
                line-height: 20px;
                border-radius: 22px;
                height: 35px;
                margin-right: 20px;
                &.unique-accept {
                  width: 200px;
                }

                .right-arrow {
                  width: 15px;
                  height: 15px;
                  margin-left: 10px;
                }

                .left-arrow {
                  width: 15px;
                  height: 15px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
